


























export default {
  data() {
    return {
      popupActivo4: false
    };
  },
  components: {
    'vs-popup': () => import('@/components/Popup/VSPopup.vue'),
    tutorialVideo: () => import('@/components/Tutorial/Tutorial.vue')
  },
  methods: {
    open() {
      this.popupActivo4 = true;
    },
    thanks() {
      localStorage.tutorialCompleted = true;
      this.popupActivo4 = false;
    },
    problem() {
      this.popupActivo4 = false;
      this.openSnackHelp();
      // localStorage.tutorialCompleted = true;
    },
    openSnackHelp() {
      this.$store.state.help = true;
      this.$store.state.snackbar = {
        open: true,
        text: this.translate('startLogin')
      };
      this.$store.commit('openSideBar', true);
    },
    translate(name) {
      return this.$vuetify.lang.t('$vuetify.Video.' + name);
    }
  },
  computed: {
    tutorialCompleted() {
      return localStorage.tutorialCompleted != undefined;
    }
  }
};
